@tailwind base;
@tailwind components;
@tailwind utilities;

/* to remove default arrows in input element */
@layer base {
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }