.sidebar {
    flex: 1;
    // border-right: 0.5px solid rgb(230, 227, 227);
    height: 92vh;
    // margin-top: 60px;
    // position: fixed;
    position: fixed;
    display: block;
    // margin-right: 60px;
    width: 15%;
    padding-left: 0%;
    left: 0;
    top: 60px;
    bottom: 0;

    @media(max-width: 1024px) {
        display: none;
    }


    hr {
        height: 0;
    }

    
}