.manageBusinessContainer {
    width: 85%;

    @media (max-width: 1024px) {
        width: 100%;
    }

    @media (max-width: 640px) {
        padding: 1rem 1rem;
    }
}