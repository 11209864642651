.table {
  width: 100%;
  
  .productCell {
    display: flex;
    align-items: center;
    gap: 10px;

    .productImage {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  .status {
    padding: 5px;
    border-radius: 5px;

    &.Approved {
        background-color: rgba(0, 128, 0, 0.151);
        color: green;
    }

    &.Pending {
        background-color: rgba(189, 189, 3, 0.151);
        color: goldenrod;
    }
  }
}
