.products {
    padding: 2em 1em;
    margin-bottom: 2em;
    display: flex;
    gap: 1em;
    
    @media (max-width: 1024px) {
        // padding: 2em 0;
    }

    @media (max-width: 768px) {
        flex-direction: column;
        padding: 0;
    }


    .left {
        width: 15%;
        border-right: 1px solid;
        position: sticky;
        top: 0;
        padding: 1em;

        @media (max-width: 768px) {
            display: none;
        }
    }

    .right {
        width: 70%;

        @media (max-width: 768px) {
            width: 100%;
            padding: 0 1em;
        }
    }
}