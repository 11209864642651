.inputThumb, 
.inputThumb::-webkit-slider-thumb {
    appearance: none;
}

.inputThumb::-webkit-slider-thumb {
    background-color: violet;
    box-shadow: 0 0 1px 1px #ced4da;
    border: none;
    height: 18px;
    width: 18px;
    border-radius: 50%;
    position: relative;
    pointer-events: all;
    cursor: pointer;
}

.inputThumb {
    position: absolute;
    height: 0;
    margin-top: 10px;
    width: 100%;
    pointer-events: none;
}

.thumb--zindex-3 {
    z-index: 3;
}

.thumb--zindex-4 {
    z-index: 4;
}

.slider {
    position: relative;
    width: 100%;
    margin-top: 7px;
    
    .slider__track {
        position: absolute;
        background-color: gray;
        width: 100%;
        height: 5px;
        left: 0;
        z-index: 1;
    }
    
    .slider__range {
        position: absolute;
        background-color: violet;
        height: 5px;
        z-index: 2;
    }
}