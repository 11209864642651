.home {
  width: 85%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media (max-width: 1024px) {
    width: 100%;
  }

  .widgets {
    display: flex;
    gap: 1em;
    width: 100%;
    justify-content: space-between;

    @media(max-width: 640px) {
      flex-direction: column;
    }
    // margin: 0 auto;
    // overflow-x: auto;
  }

  .list-container {
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    padding: 20px;
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;

    .title {
      font-weight: 500;
      color: gray;
      margin-bottom: 15px;
    }
  }
}
