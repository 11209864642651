.orderContainer {
    width: 85%;

    @media (max-width: 1024px) {
        width: 100%;
    }

    .search-container {
        @media (max-width: 350px) {
            display: flex;
            flex-direction: column;
            justify-content: start;
            align-items: start;
        }
    }
}