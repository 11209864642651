.card {
  width: 280px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 50px;

  link {
    .image {
      width: 100%;
      height: 350px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    h2 {
      font-size: 16px;
      font-weight: 400;
    }

    h3 {
      font-size: 18px;
      font-weight: 500;
    }
  }
}
