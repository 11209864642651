.navbar {
  height: 80px;
  position: relative;

  .wrapper {
    padding: 10px 30px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }

  .item {
    display: flex;
    align-items: center;
    font-size: 15px;
    gap: 5px;

    img {
      width: 40px;
      height: 40px;
      object-fit: contain;
    }
  }

  .left {
    display: flex;
    align-items: center;
    gap: 25px;
  }

  .center {
    font-size: 30px;
  }

  .right {
    display: flex;
    align-items: center;
    gap: 25px;

    .icons {
      display: flex;
      align-items: center;
      gap: 15px;
      color: gray;
      cursor: pointer;

      .cart-icon {
        display: flex;
        position: relative;

        span {
          font-size: 10px;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: blue;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          position: absolute;
          top: -10px;
          right: -10px;
        }
      }
    }
  }
}
