.navbar-admin {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    padding: 1em 2em;
    height: 61px;
    border-bottom: 1px solid rgb(230, 227, 227);
    background-color: white;
    z-index: 80;
    width: 100%;

    .top {
        display: flex;
        justify-content: center;
        align-items: center;
        // padding: 8px;
        height: 60px;
        width: 20%;

        .navigation-menu {
            display: none;
            margin-right: 1em;

            @media(max-width: 1024px) {
                display: block;
            }
        }

        .logo {
            width: 100%;
            height: 80%;
            object-fit: cover;
        }

        .logoText {
            // color: purple;
            font-weight: bold;
            font-size: large;
            // text-decoration:  transparent;
        }
    }

    .navbar-left {
        display: flex;
        align-items: center;
        // border: 0.5px solid gray;
        padding-right: 5px;
        border-radius: 1px;

        input {
            margin-right: 5px;
            padding: 5px;
            border: none;
            font-size: 14px;
            outline: none;
            background: transparent;
        }
    }

    .navbar-right {
        display: flex;
        align-items: center;

        span {
            margin-right: 20px;
        }

        .avatar {
            width: 25px;
            height: 25px;
            border-radius: 50%;
        }
    }
}