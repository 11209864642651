.homepage {
  // height: 100vh;
  background-image: linear-gradient(
    to right,
    rgb(6, 182, 212),
    rgb(59, 130, 246)
  );
  width: 100%;

  .front {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    // padding: 6em 0;
    width: 90%;
    margin: 0 auto;

    @media(max-width: 640px) {
      flex-direction: column;
    }

    .left {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      gap: 1em;
      margin: 6em 0;

      h1 {
        font-weight: bold;
        font-size: 5rem;
        text-align: center;
      }

      h2 {
        font-weight: 500;
        font-size: 3rem;
        text-align: center;
      }

      p {
        font-size: 1.5rem;
        font-weight: 300;
        text-align: center;
      }

      div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: white;
        padding: 0.5em;
        padding-left: 2em;
        border: 1px solid black;
        border-radius: 2em;
        max-width: 800px;

        input {
          border: none;
          outline: none;
        }

        .signup {
          background-color: black;
          color: white;
          border: none;
          padding: 0.6em 1em;
          border-radius: 2em;
          font-size: 1rem;

          &:hover {
            background-color: rgb(55, 54, 54);
          }
        }
      }

      @media(max-width: 640px) {
        width: 100%;

        h1 {
          font-size: 3rem;
        }
      }
    }

    // .right {
    //   width: 40%;

    //   img {
    //     width: 100%;
    //   }

    //   @media(max-width: 420px) {
    //     display: none;
    //   }
    // }
  }
}

.business {
  width: 90%;
  margin: 0 auto;
  padding-top: 4em;
  padding-bottom: 4em;

  .shared-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4em;

    .item-img {
      width: 45%;
      box-shadow: 5px 5px 10px 12px lightblue;
    }

    .item-desc {
      width: 45%;

      h1 {
        font-weight: 600;
        font-size: 2rem;
        margin-bottom: 2rem;
      }

      li {
        display: flex;
        align-items: start;
        margin-bottom: 1rem;

        p {
          margin-left: 1em;
          font-size: 1.4rem;
        }
      }
    }

    @media(max-width: 1024px) {
      // flex-direction: column-reverse;

      .item-desc {
        width: 100%;
        margin-bottom: 2em;
      }

      .item-img {
        width: 100%;
      }
    }
  }

  .item-1 {
    @media(max-width: 1024px) {
      flex-direction: column-reverse;
    }
  }

  .item-2 {
    @media(max-width: 1024px) {
      flex-direction: column;
    }
  }
}