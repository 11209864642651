.home {
    .categories {
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 20px 30px;
        box-shadow: 0px 0px 7px -5px rgba(0, 0, 0, 0.5);
    }

    .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;

        img {
            width: 60px;
            height: 60px;
            object-fit: cover;
        }
    }
}