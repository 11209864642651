.product {
  padding: 1em 3em;
  display: flex;
  justify-content: space-between;
  gap: 3em;
  margin-bottom: 10em;
  margin-top: 1em;

  @media (max-width:1024px) {
    flex-direction: column;
  }

  @media (max-width:640px) {
    padding: 1em 1em;
  }

  .left {
    flex: 1;
    display: flex;
    gap: 1em;
    height: 70vh;

    .images {
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow-y: scroll;

      img {
        width: 100%;
        height: 100%;
        min-height: 10vh;
        // height: 150px;
        object-fit: cover;
        cursor: pointer;
        margin-bottom: 1em;
      }

      @media (max-width:1024px) {
        display: none;
      }
    }

    .main-image {
      flex: 5;

      img {
        width: 100%;
        // height: 600px;
        height: 100%;
        object-fit: cover;
      }

      @media (max-width:1024px) {
        max-height: 50vh;
      }
    }
  }

  .right {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1em;

    h1 {
      font-size: 32px;
      font-weight: 500;
    }

    .price {
      color: rgb(37, 134, 254);
      display: block;
      font-size: 30px;
      font-weight: 500;
    }

    p {
      font-size: 18px;
      font-weight: 300;
      text-align: justify;
    }

    .quantity {
      display: flex;
      align-items: center;
      gap: 10px;

      button {
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          background-color: rgb(241, 240, 240);
        }
      }
    }

    .add-to-cart {
      display: flex;
      align-items: center;
      gap: 20px;
      background-color: rgb(37, 134, 254);
      color: white;
      width: fit-content;
      padding: 10px 30px;
      justify-content: center;
      font-weight: 400;
    }

    .links {
      display: flex;
      align-items: center;
      gap: 20px;

      .item {
        display: flex;
        align-items: center;
        gap: 10px;
        color: rgb(37, 134, 254);
        font-size: 14px;
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      color: gray;
      font-size: 14px;
      margin-top: 10px;
      gap: 5px;

      hr {
        width: 200px;
        border: 1px solid rgb(232, 232, 232);
      }
    }

    hr {
        border: 1px solid rgb(232, 232, 232);
      }
  }
}
