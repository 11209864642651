.widget {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    // flex: 1;
    width: 30%;
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    border-radius: 10px;
    height: 120px;

    @media(max-width: 640px) {
        width: 100%;
    }

    .left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .title {
            font-weight: bold;
            font-size: 14px;
            color: rgb(160, 160, 160);
        }

        .counter {
            font-size: 28px;
            font-weight: 300;
        }

        .link {
            width: max-content;
            font-size: 12px;
            border-bottom: 1px solid gray;
            cursor: pointer;
        }
    }

    .right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;

        .percent {
            display: flex;
            align-items: center;
            gap: 5px;
            font-size: 14px;

            &.positive {
                color: green;
            }
            &.negative {
                color: red;
            }
        }
    }
}