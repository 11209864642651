.footerHome {
  display: flex;
  flex-direction: column;
  // padding: 6rem 8rem;
  width: 90%;
  margin: 0 auto;
  padding-top: 1em;
  padding-bottom: 1em;
  background-color: black;
  color: #000;

  .top {
    display: flex;
    // gap: 1rem;
    justify-content: space-between;

    .left {
      width: 20%;
      img {
        max-width: 50%;
      }

      @media(max-width: 640px) {
        img {
          max-width: 100%;
        }
      }
    }

    .right {
      display: flex;
      width: 80%;
      justify-content: space-between;

      @media(max-width: 640px) {
        display: grid;
        grid-template-columns: auto auto;
        column-gap: 1em;
        row-gap: 1em;
        width: 75%;
        font-size: 0.8rem;
      }

      .item {
        flex: 1;
        display: flex;
        flex-direction: column;
        text-align: justify;

        h1 {
          font-size: 1rem;
          font-weight: 500;
          color: rgb(121, 121, 121);
          margin-bottom: 0.5em;
        }

        span {
          color: gray;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }


  hr {
    border: 0.5px solid white;
    margin: 30px 0px;
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
        display: flex;
        justify-content: space-between;
        width: 40%;
        @media(max-width: 640px) {
          font-size: 0.6rem;
          width: 50%;
        }

        span {
            color: gray;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .right {
      width: 20%;

      ul {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
