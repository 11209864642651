.nav-l {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // position: sticky;
  top: 0px;
  left: 0px;
  right: 0px;
  padding: 1em 0;
  width: 90%;
  margin: 0 auto;
  background-color: white;

  .left {
    display: flex;
    align-items: center;
    justify-content: center;

    .logo {
      display: flex;
      align-items: center;
      margin-right: 1em;
    }

    .links {
      display: flex;
      align-items: center;
      gap: 20px;

      .item {
        display: flex;
        align-items: center;

        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }

        span {
          font-size: 16px;
        }
      }
    }
  }

  .right {
    display: flex;
    align-items: center;
    gap: 1em;

    // .login {
    //   font-size: 16px;

    //   &:hover {
    //     cursor: pointer;
    //     text-decoration: underline;
    //   }
    // }

    // .signup {
    //   background-color: black;
    //   color: white;
    //   border: none;
    //   padding: 12px 24px;
    //   border-radius: 7.5px;
    //   font-size: 16px;

    //   &:hover {
    //     background-color: rgb(55, 54, 54);
    //   }
    // }

    .expand-icon {
      display: none;
    }

    @media(max-width: 640px) {
        display: none;

        .expand-icon {
          display: flex;
        }
    }
  }

  .expanded-view {
    display: none;

    @media(max-width: 640px) {
      display: flex;
    }
  }
}

.login {
  font-size: 1rem;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.signup {
  background-color: black;
  color: white;
  border: none;
  padding: 1em 2em;
  border-radius: 0.7em;
  font-size: 1rem;

  &:hover {
    background-color: rgb(55, 54, 54);
  }
}

.expanded {
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em 0;
  border-top: 0.1em gray solid;
  
  .login {
    width: 45%;
    text-align: center;
    border: 0.01em gray solid;
    border-radius: 0.7em;
    padding: 0.5em 2em;
  }

  .signup {
    width: 45%;
    padding: 0.5em 2em;
    text-align: center;
  }

  @media(min-width: 640px) {
    display: none;
  }
}

// @media(max-width: )
