.center {
    // padding-left: 40px;
    // padding-right: 40px;
    // padding: 0 auto;
    width: 100%;
    // border-right: 0.5px solid rgb(230, 227, 227);
    // height: 100vh;

    ul {
        width: 90%;
        margin: 0 auto;
    }

    p {
        color: gray;
        font-size: 0.7rem;
        padding-top: 1em;
        padding-bottom: 0.5em;
        // padding-left: 40px;
    }

    li {
        display: flex;
        align-items: center;
        margin-bottom: 1em;
        padding-left: 1em;
        padding-top: 0.3em;
        padding-bottom: 0.3em;
        cursor: pointer;
        width: 100%;

        &:hover {
        }

        span {
            margin-left: 0.5em;
            // color: rgb(141, 141, 141);
            font-size: 1rem;
        }

        a {
            display: flex;
            align-items: center;
        }
    }
}