.cart {
  position: absolute;
  top: 83px;
  right: 15px;
  z-index: 999;
  background-color: white;
  padding: 20px;
  box-shadow: 0px 0px 7px -5px rgba(0, 0, 0, 0.5);
  width: 500px;

  h1 {
    color: gray;
    font-weight: 400;
    font-size: 24px;
    margin-bottom: 15px;
  }

  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      display: flex;
      align-items: center;
      gap: 20px;
      margin-bottom: 15px;

      img {
        width: 80px;
        height: 100px;
        object-fit: cover;
      }

      .details {
        h1 {
          font-size: 18px;
          font-weight: 500;
        }

        p {
          color: gray;
          font-size: 14px;
          margin-bottom: 10px;
        }

        .price {
          color: rgb(37, 134, 254);
        }
      }
    }
  }

  .total {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 20px;
  }

  button {
    display: flex;
    align-items: center;
    gap: 20px;
    background-color: rgb(37, 134, 254);
    color: white;
    width: fit-content;
    padding: 10px 30px;
    justify-content: center;
    font-weight: 400;
    margin-bottom: 10px;
  }

  .reset {
    font-size: 15px;
    color: red;
    cursor: pointer;
  }
}
